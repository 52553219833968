import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ComponentPreview,
  DontDo,
  Link,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  Section,
  SectionSubhead,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';
import notePreviewData from '../../../../data/previews/note.yml';
import pageHeroData from '../../../../data/pages/components.yml';

const Note = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Note;
    return <Component {...props} />;
  },
});

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      typeText:
        "We'll use this email address and cell phone number to send you updates related to your account.",
    };
  }

  handleTypeChange = type => {
    let typeText;
    if (type === 'information') {
      typeText =
        "We'll use this email address and cell phone number to send you updates related to your account.";
    } else if (type === 'warning') {
      typeText =
        "In order for coaches and athletes to see new comments, you'll have to re-share the playlist.";
    } else if (type === 'critical') {
      typeText =
        'We spotted an issue with this order. Check your billing info and try again.';
    } else {
      typeText =
        'Video, tunes, title—this reel is ready to go. Feel free to continue editing or publish as is.';
    }

    this.setState({
      typeText: typeText,
    });
  };

  render() {
    return (
      <PageWithSubNav
        pageType="design"
        title="Note"
        designCode
        subnav="components">
        <PageHero
          heroData={pageHeroData}
          tierTwo="Notifications"
          tierThree="Note"
        />
        <V5Notice
          componentName="Note"
          storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/notifications-note--docs"
        />
        <Paragraph>
          For information related to an entire interface, check the{' '}
          <Link href="/components/notifications/notice" isDesignCodeLink>
            notice
          </Link>
          . And if something comes up as the result of a workflow, use the{' '}
          <Link href="/components/notifications/toast" isDesignCodeLink>
            toast
          </Link>
          .
        </Paragraph>
        <PageNavigation
          links={[
            'Size',
            'Type',
            'Mobile',
            'Usage',
            'Microcopy',
            'Accessibility',
            'Platform',
          ]}
        />

        <Section title="Size">
          <Paragraph>
            Relevant to the note’s size are the sizes of both the icon and text.
            Consider location and interface density as you choose.
          </Paragraph>
          <ComponentPreview
            name="NoteSize"
            layout="row"
            previewData={notePreviewData.sizes}
            onChange={this.handleSizeChange}>
            <Note>
              This information will only appear for verified recruiters, not the
              general public.
            </Note>
          </ComponentPreview>
        </Section>

        <Section title="Type">
          <Paragraph>
            The four note types correspond directly to our{' '}
            <Link href="/components/icons/feedback/design">feedback icons</Link>
            .
          </Paragraph>
          <ComponentPreview
            name="NoteType"
            layout="row"
            previewData={notePreviewData.types}
            onChange={this.handleTypeChange}>
            <Note size="medium">{this.state.typeText}</Note>
          </ComponentPreview>
        </Section>

        <Section title="Mobile">
          <Paragraph>
            Small and medium notes work well on mobile phones, but reserve the
            large size for tablet views only.
          </Paragraph>
        </Section>

        <Section title="Usage">
          <SectionSubhead>Feedback</SectionSubhead>
          <Paragraph>
            Every note provides a certain type of feedback, emphasized by a{' '}
            <Link href="/guidelines/colors/utility/design#Options">color</Link>{' '}
            and{' '}
            <Link href="/components/icons/feedback" isDesignCodeLink>
              icon
            </Link>
            .
          </Paragraph>
          <DontDo
            dontText="interchange feedback colors and icons."
            doText="match feedback to the message type."
            imgFilename="note-feedback"
          />

          <SectionSubhead>Non-Dismissible</SectionSubhead>
          <Paragraph>
            Every note is either ever-present or removed by righting a wrong.
          </Paragraph>
          <DontDo
            dontText="allow them to remove context."
            doText="display the note for as long as the content is relevant."
            imgFilename="note-dismiss"
          />

          <SectionSubhead>Relative Importance</SectionSubhead>
          <Paragraph>
            Most notes should serve as subtle hints and updates in the
            interface, tied directly to just one section.
          </Paragraph>
          <DontDo
            dontText="use it to introduce an entire page or discuss a feature’s purpose."
            doText="carefully consider how helpful the note would be."
            imgFilename="note-importance"
          />

          <SectionSubhead>Location</SectionSubhead>
          <Paragraph>
            Where a note lives can make all the difference in whether or not
            it's seen and how effective the message is.
          </Paragraph>
          <DontDo
            dontText="present multiple notes together."
            doText="place the note right next to whatever it’s discussing."
            imgFilename="note-location"
          />

          <SectionSubhead>Links</SectionSubhead>
          <Paragraph>
            Feel free to hyperlink text in information and warning notes for the
            sake of providing more details. Just make sure the linked resource
            is relevant and valuable enough to distract from the current task.
          </Paragraph>
          <DontDo
            dontText="make the link mandatory or use it in place of the note itself."
            doText="frame it as supplementary to what’s mentioned in the note."
            imgFilename="note-links"
          />

          <SectionSubhead>Styling</SectionSubhead>
          <Paragraph>
            Like{' '}
            <Link href="/components/tooltip" isDesignCodeLink>
              tooltips
            </Link>
            , you should only bold or italicize one phrase per note—never an
            entire sentence, and only if the styling is absolutely necessary.
          </Paragraph>
          <DontDo
            dontText="style an entire note just to grab a user’s attention."
            doText="use styling for emphasis or clarification."
            imgFilename="note-styling"
          />
        </Section>

        <Section title="Microcopy">
          <SectionSubhead>Content Length</SectionSubhead>
          <Paragraph>
            Each note should be <strong>no longer than 170 characters</strong>.
            If you can make it shorter, great. Any longer and you risk
            interrupting their flow.
          </Paragraph>
          <DontDo
            dontText="ramble when hyperlinked text is always an option."
            doText="get to the point with only the most relevant info."
            imgFilename="note-length"
          />

          <SectionSubhead>Whose Interface?</SectionSubhead>
          <Paragraph>
            <Link href="http://hudl.com/design/writing/best-practices/personal-pronouns">
              Check these guidelines
            </Link>{' '}
            and remember “we’re” always talking to a “you”.
          </Paragraph>
          <DontDo
            dontText="write in third-person and make things weirdly impersonal."
            doText="sound like one human talking to another."
            imgFilename="note-whoseinterface"
          />

          <SectionSubhead>Body Only</SectionSubhead>
          <Paragraph>
            Regardless of what purpose the note serves (message or status),
            you’ll never need a title or subtitle.
          </Paragraph>
          <Paragraph>
            <strong>Messages</strong> should be written as complete sentences
            with proper punctuation.
          </Paragraph>
          <Paragraph>
            {' '}
            <strong>Statuses</strong> should be no longer than three words, free
            of punctuation.
          </Paragraph>
          <Paragraph>Both should be sentence case.</Paragraph>
        </Section>

        <AccessibilityAuditComponentResultsSection componentName="Notifications / Note" />

        <Section title="Platform">
          <PlatformTable />
        </Section>
      </PageWithSubNav>
    );
  }
}
